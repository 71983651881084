.h3 {
  font-weight: 700;
  font-size: 30px;
  text-transform: capitalize;
  line-height: 39px;
}

#shopify-section-1498163293234 .section-header {
  margin-bottom: 20px;
}

.footer__newsletter {
  max-width: 100%;
}

.footer__newsletter-input {
  max-width: 100%;
}

.footer__title {
  letter-spacing: 1.5px;
  font-size: 16px;
  font-weight: 600;
}

.custom-rich-text {
  text-align: center;
}

.index-section.insta-logo-list {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #eee;
}

.custom-rich-text h3 {
  margin-bottom: 10px;
}

.custom-rich-text h4 {
  margin-bottom: 10px;
}

.custom-rich-text .rich-text-image img.mb-image {
  display: none;
}

.custom-rich-text .rich-text-image a {
  display: block;
  line-height: 0px;
}

.custom-rich-text h2.section-header__title {
  max-width: 390px;
  margin: 0 auto 24px auto;
  font-size: 32px;
  line-height: 42px;
}

.product-compare .heading {
  text-align: center;
  margin-bottom: 32px;
}

.grid-product__tag--sale {
  background-color: #c3954c;
}

.grid-product__tag--sale .grid-product__price--savings {
  color: #fff;
  font-weight: 700;
  margin: 0px;
}

.product-compare .heading h2 {
  margin-bottom: 0px;
}

.product-compare .row {
  display: flex;
  flex-wrap: wrap;
}

.product-compare .row .image {
  flex: 0 0 25%;
  padding: 10px;
  line-height: 0px;
}

.product-compare .row .image img {
  aspect-ratio: 1 / 1;
}

/* /////////////// PRODUCT REVIEWS //////////// */

.product-card-slide .card .card-img-icon {
  max-width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product-card-slide .card {
  /*     position: relative; */
  flex: 0 0 50%;
  padding: 0px 5px;
  width: 50%;
}

.product-card-slide {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.product-card-slide .flickity-viewport {
  /*     min-height: 300px;
    height: 300px !important; */
  width: 100% !important;
}
/* .product-card-slide .card {
    height: 300px !important;
    width: 300px !important;
} */

.product-card-slide .card a {
  display: block;
}

.product-card-slide .card a img {
  display: block;
}
.product-card-slide .flickity-button {
  height: 20px;
  width: 20px;
}

.product-card-slide .card .card-img-top {
  aspect-ratio: 1/1;
  object-fit: cover;
}

.product-card-slide-wrap {
  margin-top: 10px;
  margin-bottom: 48px;
}

.product-card-slide .flickity-page-dot {
  width: 7px;
  height: 7px;
}
#shopify-section-1525912530555 {
  padding-top: 24px;
}

#shopify-section-1525912530555 .promo-grid__bg {
  position: relative;
}

#shopify-section-1525912530555 .promo-grid__bg img {
  object-fit: cover;
}

.product-reviews-wrap.page-width {
  max-width: 1200px;
}

.product-reviews-wrap .spr-container.spr-container {
  text-align: left;
}

.product-reviews-wrap .spr-review-footer.rte {
  text-align: right;
}

.product-reviews-wrap .spr-review-header-starratings {
  position: absolute;
  right: 0px;
}

.product-reviews-wrap .spr-review {
  position: relative;
}

.product-reviews-wrap .spr-review-header-byline {
  opacity: 0.5;
  font-style: normal;
}

.product-reviews-wrap .spr-review-reportreview {
  font-size: 11px;
  line-height: 16px;
  opacity: 1;
  border: 0px !important;
}

.product-reviews-wrap .spr-summary-actions-newreview {
  border: 0px !important;
}

.product-reviews-wrap .spr-review-content {
  margin-bottom: 14px;
}

.product-reviews-wrap .spr-content .spr-review-header-title {
  width: calc(100% - 180px);
}

.gallery-page .heading {
  text-align: center;
  margin-bottom: 30px;
}

.gallery-page .row {
  display: flex;
  flex-wrap: wrap;
}

.gallery-page .row .image {
  flex: 0 0 33.333%;
  line-height: 0px;
  padding: 10px;
}

.gallery-page .row .image img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.faq-wrapper .collapsible-trigger {
  padding-left: 0px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: 700;
}

.faq-wrapper .collapsible-trigger span {
  flex: 1 0 0;
}

.faq-wrapper .collapsible-trigger span.minus,
.faq-wrapper .collapsible-trigger span.plus {
  flex: 0 0 auto;
  padding-left: 24px;
}

.faq-wrapper .collapsible-content__inner {
  padding-bottom: 12px;
}

.faq-wrapper .collapsible-trigger span.minus {
  display: none;
}

.faq-wrapper .collapsible-trigger.is-open span.plus {
  display: none;
}

.faq-wrapper .collapsible-trigger.is-open span.minus {
  display: block;
}

.faq-wrapper .faq:last-child {
  border-bottom: 2px solid #000;
}

.faq-wrapper .faq {
  border-top: 2px solid #000;
}

.pb-sticky-bar-form .sealsubs-target-element.sealsubs-full {
  display: none !important;
}

.pb-sticky-bar-form .product__quantity.product__quantity--button {
  display: none;
}

.pb-sticky-bar-form .shopify-payment-button {
  display: none;
}

.pb-sticky-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  padding: 0 40px;
  margin: 0 auto;
}

.pb-sticky-title .title {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
}

.pb-sticky-title .price span {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 600;
}

.pb-sticky-title .price {
  margin-bottom: 0px;
  line-height: normal;
}

.product-single__form {
  margin-bottom: 0px;
}

.product-sticky-cart {
  position: fixed;
  /* top: 0px; */
  bottom:0px;
  width: 100%;
  z-index: 99;
  padding: 10px 0px;
  display: none;
}

.product-sticky-cart.show {
  display: block;
}

#AddToCart- {
  padding: 10px 20px;
  height: auto;
  min-height: auto;
  border-radius: 4px;
}

.footer__logo-mb-text .text-social {
  display: none;
}

.collection-list-btn {
  text-align: center;
}

.footer__logo-mb-text .text p {
  max-width: 230px;
}

.footer__logo-mb-text .text {
  margin-top: 32px;
}

.flickity-button:hover {
    background: #c3954c;
}

.flickity-button:focus {
    box-shadow: none;
}

.top-bar.index-section {
    margin: 0;
}

.top-bar .page-width {
    padding: 2px 20px;
    margin: 0;
    max-width: 100%;
}

.top-bar .logo-bar {
    margin: 0;
}

.top-bar .logo-bar__item {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.top-bar .top_bar-text {
    font-weight: 300;
    font-size: 12px;
    padding-left: 8px;
}

.top-bar .top_bar-text {
    font-weight: 300;
    font-size: 12px;
    padding-left: 8px;
}

////// pop video start ////


.video-popup-block .image {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.video-popup-block .image a {
    position: relative;
    padding: 0 5px;
}

.video-popup-block .image svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    color: #fff;
}

.video-popup-block .image svg {
    color: #fff;
}

.video-popup-block .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    color: #fff;
    position: relative;
    border: 2.5px solid #ea2f4c;
    padding: 3px;
}

.video-popup-block .img img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.video-popup-block .image {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow: auto;
    justify-content: space-between;
}

.video-popup-block .image .text {
    margin-bottom: 0px;
    margin-top: 7px;
    font-size: 11px;
}

#shopify-section-custom__video-popup {
    padding-top: 25px;
    padding-bottom: 25px;
    display: none;
}

.video.popup-video_video {
    padding: 0px;
    line-height: 0px;
}

.video.popup-video_video video {
    max-width: 285px;
}

.video.popup-video_video button.fancybox-button {
    color: #fff;
}


////// pop video end ////



@media only screen and (max-width: 768px) {
  .footer__logo-mb-text .text {
    margin-top: 0px;
  }

  .top-bar .logo-bar__item {
      flex: 0 1 160px;
  }

  .footer__logo-mb-text .text-social {
    display: block;
  }

  .footer__item-padding.footer-newsletter-col {
    text-align: left;
    margin-top: 20px;
  }

  .mb-text-columns-slider .grid__item .image-wrap img {
      opacity: 1;
  }

  [data-center-text="true"] .footer__title {
    text-align: left;
  }

  .footer__logo {
    margin-bottom: 16px;
  }

  .gallery-page .row .image {
    flex: 0 0 50%;
    padding: 5px;
  }

  .product-compare .row .image {
    flex: 0 0 50%;
    padding: 5px;
  }

  .fancybox-slide--video .fancybox-content {
    height: auto;
  }

  .custom-rich-text .rich-text-image img.dt-image {
    display: none;
  }

  .custom-rich-text .rich-text-image img.mb-image {
    display: block;
  }

  .site-footer .footer__collapsible {
    text-align: left;
  }

  .footer__logo img {
    max-width: 150px;
  }

  .footer__logo a {
    height: auto !important;
  }

  .footer__logo-mb-text .text {
    max-width: 200px;
    margin: 0 auto;
  }

  .footer__logo-mb-text .text p {
    letter-spacing: 1.5px;
    font-size: 16px;
    font-weight: 600;
  }

  .footer__logo-mb-text {
    margin-bottom: 32px;
    display: block;
  }

  .grid__item.footer__item--1494292487693 .no-bullets.footer__social {
    display: none;
  }

  .insta-logo-list .page-width {
    overflow: hidden;
    overflow-x: scroll;
  }

  .insta-logo-list .page-width .logo-bar {
      white-space: nowrap;
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 10px;
      justify-content: unset;
  }

  .insta-logo-list .logo-bar .logo-bar__item {
      width: 20vw;
      flex: 0 0 20vw;
      display: inline-block;
      float: none;
      white-space: normal;
  }

  .top-bar .logo-bar {
      display: flex;
      white-space: nowrap;
      overflow-x: auto;
      flex-wrap: initial;
      justify-content: flex-start;
  }
    
}

@media only screen and (max-width: 767px) {
  .top-bar .logo-bar__item {
      flex: none;
      padding-bottom: 3px;
      margin-left: 5px;
  }

  .top-bar .logo-bar {
      display: flex;
      white-space: nowrap;
      overflow-x: auto;
      flex-wrap: initial;
      justify-content: flex-start;
  }

  #shopify-section-custom__video-popup {
      display: block;
  }

  .video-popup-block .image::-webkit-scrollbar {
      display: none !important;
  }

  .logo-bar.logo-bar--ecf2a6c3-054d-47b9-956a-22b466dd4429::-webkit-scrollbar {
      display: none !important;
  }
  .footer__logo-mb-text .text p {
       margin: 0 auto 15px auto;
  }

  
}

@media only screen and (max-width: 480px) {
  .gallery-page .row .image {
    flex: 0 0 100%;
    padding: 5px;
  }
  .pb-sticky-bar {
    padding: 0 16px;
  }

  #AddToCart- {
    letter-spacing: 1px;
    padding: 8px 16px;
  }
  .pb-sticky-title .title,
  .pb-sticky-title .price span {
    font-size: 11px;
  }

  .custom-rich-text h2.section-header__title {
    font-size: 28px;
    line-height: 34px;
  }

  .insta-logo-list .page-width {
      padding: 0 10px;
  }

  .insta-logo-list .page-width .logo-bar {
      padding-left: 0;
      flex-wrap: nowrap;
      overflow: auto;
      justify-content: flex-start;
      margin-bottom: 0px;
      margin-top: 14px;
  }

  .insta-logo-list .logo-bar .logo-bar__item {
      margin: 0 6px;
      width: 85px;
      flex: 0 0 auto;
  }

  .top-bar .page-width {
      padding: 1px 20px;
  }
    
}

/**** ****/
 @media (max-width: 769px){
.promo-grid .flex-grid__item--50 {
    flex-basis:100%;
}   
}
.promo-grid .flex-grid__item--50:last-child{
  padding-bottom:10px;
}
/* 28-12 product page responsive css */
.cart__page .sezzle-shopify-info-button ,.cart__page square-placement{
    display: none;
}
@media (max-width: 769px){
.gurante-wrapper-content p {
    line-height: 19.2px !important;
    font-size: 12px !important;
}
}
@media (max-width: 480px){
.gurante-wrapper-content p {text-align: left !important;}
.gurante-wrapper-content {margin-left: 15px !important;}
.gurante-wrapper-flex {display: flex !important;}
}
@media (max-width: 360px){
.gurante-wrapper-content {margin-left: 10px !important;}
}
/* 29-12  */
.product-single__meta .collapsibles-wrapper .collapsible-trigger {
    font-weight: 700;
}
.custom_slider{margin-bottom: 100px;}
.collection-content .image-wrap{
      padding-bottom: 100% !important;
}
.collection-content .image-wrap img{
      aspect-ratio: 1 / 1;
}
@media (max-width: 1199px){.custom_slider{margin-bottom: 70px;}}
@media (max-width: 989px){.custom_slider{margin-bottom: 50px;}}
@media (max-width: 768px){.custom_slider{margin-bottom: 30px;}}

/* Improvement section CSS */
  .improvement_sec_img{
    line-height: 0;
  }
  .pdp-impro{
    padding: 0px !important;
  }
  .improvement_sec {
      text-align: center;
      padding: 0 15px;
  }
  .improvement_sec_content p {
    line-height: 30px;
    margin-bottom: 19px;
    font-size: 26px;
    font-weight: 600;
        color: #000000;
 }
  .improvement_sec_img img{
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 25px;
  }

/* 22-03 */
free-shipping-bar .shipping-bar__progress:after {
     content: "";
    transform: scaleX(var(--progress));
    background: #003c8c;
    transition: transform .2s;
    display: flex;
    height: 10px;
    border-radius: 20px;
    margin: 0 auto 0 0;
    transform-origin: left;
}
free-shipping-bar span.shipping-bar__text {
    color: red;
    text-align: center;
    display: block;
    font-family: 'Poppins';
}
free-shipping-bar {
    margin: 10px 0 0 0;
}
.shipping-bar__progress{
    background: #eee;
    width: calc(100% - 40px);
    display: block;
    height: 10px;
    margin: 0 auto;
    border-radius: 20px;
}
/* .price_pdp .sezzle-shopify-info-button ,.price_pdp square-placement {
    display: none !important;
} */
 .product-single__title{
      font-size: 36px;
    font-weight: 600;
    line-height: 36px;
  margin-bottom: 20px;
}
.price_pdp .product__price {
    font-size: 30px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0.4000000059604645px;
    color: #C3954C;
}
.price_pdp  .product__price--compare .bacurr-money {
    color: #808080 !important;
    font-family: Libre Franklin;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.4000000059604645px;
    text-decoration: line-through;
}
.price_pdp  .product__price--compare{
    text-decoration: none;
  margin-left: 10px;
      line-height: 0 !important;
}
.price_pdp {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  margin-bottom: 30px;
}
.product-single__meta .collapsibles-wrapper .collapsible-trigger{
      border-left: 0;
    border-right: 0;
    text-align: left;
    padding: 20px 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 2.799999952316284px;
}
.free_shi_data_all {
    display: flex;
    border: 1px solid #C3954C;
    border-radius: 10px;
    padding: 20px;
    justify-content: space-between;
      margin: 30px 0;
}
.free_shi_data {
    display: flex;
    align-items: center;
    color: #000000;
    font-family: Libre Franklin;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    gap: 20px;
}
.free_img_text {
    max-width: 96px;
}
label.sls-purchase-options-label{
      color: #000000 !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    text-align: left;
      margin-bottom: 17px;
}
.sls-selling-plan-group-first-line{
      justify-content: flex-start;
    gap: 10px;
    align-items: center;

}
.sealsubs-container .sls-option-container label{
  font-size: 16px;
    line-height: 25.6px;
}
.sls-total-price{
      align-self: center;
    color: #6D6D6D;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
}
.sealsubs-container .sls-purchase-options-container .sls-option-container, .sealsubs-container .sls-purchase-options-container .sls-option-container.sls-active {
    padding: 18px 13px;
}
.sls-more-info-text{
      font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #000;
    padding: 0;
    margin-top: 15px;
}
.sls-more-info-container {
    padding: 0;
    font-size: 0;
}
.product__quantity label{
      font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 3px;
}
.product__quantity{
      margin-top: 30px;
}
.visitor-font-weight-inherit span {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
}
.visitor-font-family-inherit {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
}
.payment-buttons .ultimate-badges {
    margin-top: 18px;
}
.ultimate-badges .trustBarTitle {
    font-family: Libre Franklin;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.24px;
    text-align: center;
    color: #212B36 !important;
      margin-bottom: 10px !important;
}
.ultimate-badges {
    border-bottom: 1px solid #E0E0D7;
    margin-bottom: 30px;
      padding-bottom: 6px;
}
.improvement_sec_content p{
  font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    max-width: fit-content;
    flex-basis: fit-content;
      display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  margin: 0;
}
.improvement_sec_content p strong {
    color: #4987B8;
    font-family: Libre Franklin;
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    text-align: center;

  display: block;
}
.improvement_sec_bg {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #E0E0D7;
}
.improvement_sec_content {
    display: flex;
  padding: 0 24px;
      justify-content: space-between;
}
.gurante-wrapper{
      border: 1px solid #E0E0D7;
    border-radius: 10px;
    padding: 14px 14px 13px 15px;
}
.gurante-wrapper-content{
      color: #4D4D4D;
}
.product-single__meta .price_pdp .product__price {
  display:block !important;
}
.product-single__meta .product__price ,.product-single__meta .product__price-savings{
    display: none;
}
.qp--align-left{
      flex-wrap: nowrap !important;
}
quadpay-widget, shopify-payment-terms,.sezzle-shopify-info-button .sezzle-checkout-button-wrapper .sezzle-button-text.sezzle-left{
    font-family: Libre Franklin;
    font-size: 14px;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    color: #4D4D4D;
}
.all_qty .sealsubs-target-element {
    display: none;
}
/* .sezzle-shopify-info-button {
    display: none !important;
}
.all_qty .sezzle-shopify-info-button{
  display:block !important;
} */
shopify-payment-terms {
    display: none;
}
@media(max-width:1199px){
  .improvement_sec_content p {
    line-height: 26px;
    font-size: 22px;
    margin-bottom: 10px;
  }
}
@media(max-width:768px){
  .improvement_sec_content p {
    line-height: normal;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .promo-grid__container--tint .type-banner__content {
    flex-wrap: wrap;
}
  .type-banner p:nth-child(2) {
    font-size: 12px;
    line-height: 20px;
}
}
@media(max-width:575px){
  .improvement_sec_content p {
    font-size: 16px;
  }
  .pdp-impro {
    padding: 0 0 15px 0!important;
  }
}

/* 28-03 pdp */
.pdp_slider .used-trust {
    display: flex !important;
    flex-wrap: wrap;
    gap: 10px;
      justify-content: center;
}
.pdp_slider {
    max-width: 1452px;
    margin: 75px auto 0 auto;
    padding: 0 16px;
}
.pdp_slider .top_text-title {
    font-family: Libre Franklin;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: 0.4000000059604645px;
    text-align: center;
}
.pdp_slider .top_text-heading {
    font-family: Poppins;
    font-size: 25.5px;
    font-weight: 500;
    line-height: 25.5px;
    text-align: center;
    margin: 15px 0 32px 0;
}
.pdp_slider .marquee-item {
    line-height: 0;
    max-width: calc(25% - 10px);
    flex-basis: calc(25% - 10px);
}

.pdp_slider .marquee_img img{
      width: 100%;
    height: 100%;
}
.pdp_slider .marquee_img {
    padding: 0 !important;
}
div#shopify-section-slider-pdp {
    margin: 0;
}
.pdp_slider .product-single__title{
      font-size: 36px;
    font-weight: 600;
}
  .product-single__description {
    margin-bottom: 0;
}
.product-card-slide-wrap{
      margin-bottom: 55px;
}
  .sls-more-info-text{
       margin-top: 0px;
    padding: 0 !important; 

  }
.visitor-counter-content-box-carecartbysalespop-2020 ,.price_pdp .qp-widget-container {
    display: none !important;
}
button.shopify-payment-button__more-options.BUz42FHpSPncCPJ4Pr_f {
    color: #4D4D4D !important;
    text-decoration: underline !important;
}
div#visitor_conatct {
    margin: 0px 0 10px 0;
}
.is-empty .cart-total-shipping{
  display:none;
}
.gurante-wrapper-content p{
      color: #4D4D4D !important;
    font-family: Libre Franklin!important;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 29px !important;
    letter-spacing: 0 !important;
}
@media(min-width:1399px;){
  .site-nav__dropdown  .grid.grid--center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
  .site-nav__dropdown .grid__item {
padding-left:10px;
    max-width: 14%;
}
  label.sls-purchase-options-label{
      font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 26px !important;
}

}
@media(max-width:1280px){
  .improvement_sec_content p strong {
      font-size: 28px;
      line-height: 28px;
  }
}
@media(max-width:1199px){
  .gurante-wrapper-content p{
    font-size: 14px !important;
    line-height: 18px !important;
}
}
@media(max-width:989px){
  .product-single__meta {
    padding-left: 10px;
}
  .free_shi_data_all{
        padding: 10px;
  }
   .sls-total-price{
        font-size: 18px;
    line-height: 23px;
  }
  .product-single__title {
    font-size: 30px;
    line-height: 32px;
}
  .price_pdp .product__price {
    font-size: 26px;
    line-height: 26px;
  }
  .price_pdp .product__price {
    font-size: 18px;
  }
  .improvement_sec_bg {
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
  .ultimate-badges {
   
    margin-bottom: 20px;
  }

  .gurante-wrapper {
    margin-bottom: 15px;
}
.product-single__meta .social-sharing {
    margin-top: 20px;
}
  .free_shi_data {
    flex-direction: column;
        text-align: center;

  }
    .improvement_sec_content p strong{
        font-size: 24px;
    line-height: 28px;
  }
  .improvement_sec_content p {
    font-size: 14px;
}
  .improvement_sec_content{
        padding: 0;

  }
  .improvement_sec_content p{
        text-align: center;
  }
  .sealsubs-container .sls-option-container label {
    font-size: 14px;
    line-height: 22.6px;
}
 
}
@media(max-width:749px){
  .pdp_slider .marquee-item {
    line-height: 0;
    max-width: calc(33.33% - 10px);
    flex-basis: calc(33.33% - 10px);
}
  .pdp_slider .top_text-heading {
    font-size: 20.5px;
    line-height: 22.5px;
    margin: 10px 0 20px;
}
  .pdp_slider{    
    margin: 10px auto 0;
  }
  .product__quantity {
    margin-top: 0;
    text-align: left;
}
.free_shi_data {
    flex-direction: row;
    text-align: start;
}
  .qp--align-center {
    justify-content: start;
}
  .product-single__meta {
    padding-left: 0;
}
}
@media(max-width:575px){
  .pdp_slider .marquee-item {
    line-height: 0;
    max-width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
}
    .improvement_sec_content p strong{
        font-size: 20px;
    line-height: 22px;
  }
  .free_shi_data{
    flex-direction: column;
        font-size: 14px;
    line-height: 18px;
    gap: 10px;
  }
  .free_shi_data_all{
        margin: 20px 0;
    padding: 10px;
  }
  .product-single__meta .collapsibles-wrapper .collapsible-trigger{
        padding: 15px 8px;
    font-size: 16px;
    line-height: 18px;
  }
.product-single__title {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 10px;
}
  .price_pdp .product__price {
    font-size: 20px;
    line-height: 26px;
  }
  .price_pdp .product__price {
    font-size: 16px;

  }

    .improvement_sec_bg {
    margin-bottom: 5px;
  }
  .free_shi_data {
    flex-direction: column;
    text-align: center;
}
  .sls-total-price {
    font-size: 14px;
    line-height: 18px;
}
  .sealsubs-container .sls-option-container label {
    font-size: 12px;
    line-height: 15.6px;
}
.sealsubs-container label{    max-width: 110px;}
  .type-banner__text{    padding: 10px 0;}
}
/* 05/04 */
.pdp_slider .marquee-item{max-width: calc(25% - 20px);    flex-basis: calc(25% - 20px);}
.pdp_slider .used-trust{gap: 20px;}
.pdp_slider .marquee_img img{aspect-ratio: 1 / 1;}


.product-recommendations .image-wrap , .product__photos .product-image-main .image-wrap {
  padding-bottom: 0 !important;
    position: relative;
    width: 100%;
    height: fit-content !important;
}
.product-recommendations .image-wrap:after , .product__photos .product-image-main .image-wrap:after {
    position: relative;
    width: 100%;
    content: '';
    height: 100%;
    padding-bottom: 133%;
    display: block;
}
.product-recommendations .image-wrap img , .product__photos .product-image-main .image-wrap img{
width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.product-recommendations .grid-product__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 42px;
}
.gurante-wrapper{
  border-radius: 20px;
}
.product-single__meta .collapsibles-wrapper .collapsible-trigger{
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 2.8px;
    padding: 20px 15px;
  /* border: 1px solid #E8E8E1 */
}
.product__photos  .product__thumb-item .image-wrap{
  padding-bottom: 133.33% !important;
}
/* .product-card-slide-wrap .flickity-page-dots .flickity-page-dot:last-child {
    display: none;
} */
@media only screen and (min-width: 769px){
.template-product .product-single__sticky {position: unset;}
}
/* 08/06 */
.template-index .improvement_sec_content p{
  display: block;
    text-align: center;max-width: 100%;
  line-height: 30px;
    margin-bottom: 19px;
    font-size: 26px;
    font-weight: 600;
}
.template-index .improvement_sec_content {
    display: block;
     text-align: center;
    margin: auto;
}
.template-index .improvement_sec_bg{
      padding-bottom: 0;
    border-bottom: none;
}
.sealsubs-target-element label.sls-purchase-options-label {font-size: 20px !important;width: 100% !important;max-width: 100%;line-height: 26px !important;margin-bottom: 10px !important;}
.free_shi_data_all .free_img {    max-width: 70px;    max-height: 70px;}
.product-single__meta .collapsible-trigger-btn--borders+.collapsible-content .collapsible-content__inner{padding: 20px;}
.product__photos .product__thumb-item .image-wrap .product__thumb  img{aspect-ratio: 1 / 1.33; object-fit: contain;}

.product-card-slide-wrap .gallery__page-dots .gallery__page-dot-item{
  display: block;
    width: 7px;
    height: 7px;
    padding: 0;
    margin: 0 8px;
    background: hsl(0 0% 20% / 25%);
    border-radius: 50%;
    cursor: pointer;
    appearance: none;
    border: none;
    text-indent: -9999px;
    overflow: hidden;
}
.product-card-slide-wrap .gallery__page-dots .gallery__page-dot-item.is-selected{
      background: hsl(0 0% 20% / 100%);
}
.product-card-slide-wrap .gallery__page-dots {
    display: flex;
    align-items: center;
    justify-content: center;margin: 18px 0 0 0;
}
.product-single__meta .social-sharing svg path{
  fill: #444 !important;
}

@media (max-width: 1199px){
.template-index .improvement_sec_content p {line-height: 26px;font-size: 22px;margin-bottom: 10px;}
  .pdp_slider .used-trust {gap: 15px;}
}
@media (max-width: 989px){
  .price_pdp  .product__price--compare .bacurr-money {font-size: 18px;}
  .price_pdp .product__price{font-size: 20px;}
}
@media (max-width: 768px){
.template-index .improvement_sec_content p {line-height: normal;font-size: 18px;margin-bottom: 10px;}
  .price_pdp  .product__price--compare .bacurr-money , .price_pdp .product__price{line-height: normal;}
  .price_pdp  .product__price--compare .bacurr-money {font-size: 18px;}
  .price_pdp .product__price{font-size: 20px;}
  .sealsubs-target-element label.sls-purchase-options-label {    max-width: 100%; font-size: 18px !important;line-height: normal !important}
}
@media (max-width: 575px){
.template-index .improvement_sec_content p {font-size: 16px;}
.price_pdp  .product__price--compare .bacurr-money {font-size: 14px;}
  .price_pdp .product__price{font-size: 16px;}
.sealsubs-target-element label.sls-purchase-options-label {    max-width: 100%; font-size: 16px !important;}
.pdp_slider .marquee-item {max-width: calc(50% - 20px);flex-basis: calc(50% - 20px);}
.product-single__meta .collapsible-trigger-btn--borders+.collapsible-content .collapsible-content__inner {padding: 15px;}
.payment_gatway square-placement , .sezzle-shopify-info-button .sezzle-checkout-button-wrapper .sezzle-button-text.sezzle-center {text-align: center;}
.product-single__meta .seal-col-radio .sls-selling-plan-group-first-line input[type="radio"] {vertical-align: middle !important;}
}
/* 10-04 */
.product__photos .product__thumb-item .image-wrap .product__thumb img{background: #bddbff;}
/* 10-05 */
.product-single__meta .timer-store-front, .all_qty .payment_gatway, .all_qty #visitor_conatct, .payment-buttons .ultimate-badges{
  display:none;
}
.collapsible-trigger-btn--borders+.collapsible-content .collapsible-content__inner{
  border:0 !important;
}
button.shopify-payment-button__more-options.BUz42FHpSPncCPJ4Pr_f{
  margin-bottom:30px;
}
.sls-more-info-container{
  padding-top:10px !important;
  padding-bottom:0px !important;
  line-height: 0;
}
.all_qty{
  margin-top:30px
}
.product-single__meta hr.hr--medium {
    margin: 30px auto;
}
.template-index img.grid-product__image.lazyautosizes.lazyloaded{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.template-index .grid-product__image-mask .image-wrap{
    padding-bottom: 134% !important;
}
.money-back {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto;
    gap: 15px;
    font-size: 18px;
    font-weight: 600;
}
.custom_slider .loox-reviews-default {
    margin-top: 55px;
}
.product-reviews-section {
    margin: 75px auto 222px;
}
.template-index .grid-product__title.grid-product__title--body {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 42px;
}
/*Footer Blank  */
#root{height: 0px; display:none;}
.template-product #root{display:block; font-size:0; line-height:0;}
.template-index #root{display:none;}
#shopify-chat{z-index: 99; position: relative;}
/*Footer Blank  */
.drawer{z-index:100;}
.price_pdp square-placement{display:none !important;}
/* html.js.supports-touch {
    padding-bottom: 0 !important;
} */
html.js {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}
.template-product footer.site-footer .page-width {
    padding-bottom: 55px;
}
footer.site-footer .page-width{
  padding-bottom:10px;
}
@media(max-width:1280px){
  .pdp_slider{ margin: 0 auto 0;}
  .product-reviews-section {
    margin: 75px auto 175px;
  }
  #shopify-chat inbox-online-store-chat#ShopifyChat{bottom: 0px !important;}
}
@media(max-width:989px){
button.shopify-payment-button__more-options.BUz42FHpSPncCPJ4Pr_f{ margin-bottom:20px;}
.product-single__meta hr.hr--medium {margin: 20px auto;}
.gurante-wrapper{margin-bottom: 20px !important;}
.product-single__meta .collapsibles-wrapper .collapsible-trigger{font-size: 16px; line-height: 18px;}
.product-card-slide-wrap {margin-bottom: 0;}
.product-reviews-section {  margin: 75px auto 140px;}
}
@media(max-width:768px){
  .product-reviews-section {  margin: 75px auto }
  .template-index .grid-overflow-wrapper{padding-bottom: 5px;}
    /* html.js.shopify-features__smart-payment-buttons--enabled{padding-bottom:50px !important;} */
}
@media(max-width:480px){
  .drawer--right{width: 100%;max-width: 100%;}
  .money-back{ gap: 12px;  font-size: 16px;letter-spacing: normal;  }
  .product-reviews-section {  margin: 35px auto }
}
@media(max-width:375px){
  .money-back{ gap: 9px;  font-size: 14px;  }
  .template-index .collection-item__title--overlaid-box>span{ width: 100%; letter-spacing: .1em !important; }
  .sticky-add-to{letter-spacing: 0.5px !important;  padding: 8px 8px !important;} 
}
